<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
            <h3 class="font-weight-bolder">
              {{ $t('productLot') }}
            </h3>
            <div class="d-flex align-items-center">
              <div class="anan-input__inner anan-input__inner--normal">
                <input
                  v-model="search_val"
                  type="text"
                  :placeholder="this.$t('search')"
                  class="anan-input__input"
                  @keyup.enter="getData()"
                >
                <div class="anan-input__suffix">
                  <i class="anan-input__clear-btn anan-icon" />
                  <i
                    class="anan-input__suffix-icon anan-icon"
                    @click="getData()"
                  >
                    <i class="fal fa-search" />
                  </i>
                </div>
              </div>

              <b-form-select
                v-model="selectedRadio"
                :options="optionsRadio"
                class="w-50 ml-1"
                size="sm"
                @change="getData()"
              />
            </div>
            <!-- <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData()"
                    >

                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData"
                      >
                        <i class="fal fa-search" />
                      </button>

                    </div>
                      <b-form-select
                        v-model="selectedRadio"
                        :options="optionsRadio"
                        class="w-50 ml-1"
                        size="sm"
                      />
                  </div>
                </div>
              </div>

            </div> -->
          </div>
          <div class="d-flex mt-1">
            <button
              type="button"
              class="anan-button--xl-large anan-button anan-button--primary anan-button--normal ml-2"
              @click="showAddNew()"
            >
              <span>+ {{ $t('addProductLot') }} </span>
            </button>

            <button
              type="button"
              class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
              @click="$refs['modal-print'].show()"
            >
              <span>
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-25 cursor-pointer"
                /> {{ $t('print') }}
              </span>
            </button>
          </div>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              class="test"
              :items="itemss"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(name_lot)="data">
                <span
                  :class="`mb-0 text-black badge badge-${data.item.transportType === 1 ? 'warning': 'danger'}`"
                >
                  {{ data.item.transportType === 1 ? 'รถ': 'เรือ' }}
                </span>
                <b-link
                  :to="{name: 'admin-warehouse-edit', params: {id: data.item.name_lot}}"
                  target="_blank"
                >
                  <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                  &nbsp;
                  <span>{{ data.item.lot_qc ? 'QC': '' }}  {{ data.item.name_lot }}</span>
                </b-link>
              </template>
              <template #cell(come_chinesewarehouse)="data">
                {{ data.item.come_chinesewarehouse ? data.item.come_chinesewarehouse: '-' }}
              </template>
              <template #cell(out_chinesewarehouse)="data">
                {{ data.item.out_chinesewarehouse ? data.item.out_chinesewarehouse: '-' }}
              </template>
              <template #cell(come_thaiwarehouse)="data">
                {{ data.item.come_thaiwarehouse ? data.item.come_thaiwarehouse: '-' }}
              </template>
              <template #cell(lot_file)="data">
                <span
                  v-for="(filess,index) in data.item.lot_file"
                  :key="index"
                  class="od-pc-offer-price-common "
                >
                  <a @click="checkedfile(filess.pathfile)">
                    {{ filess.losarr }}
                  </a>
                  <!-- <span
                v-for="filess in viewfiles"
                :key="filess"
                class="od-pc-offer-price-common "
              >
                <a
                  @click="checkedfile(filess.key_filepath)"
                >
                  {{ filess.namefile }}
                </a> -->
                  <!-- </span> -->
                </span></template>
              <template #cell(print)="data">
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="printLot(data.item)"
                />
              </template>
              <template #cell(actions)="data">
                <b-link
                  :to="{name: 'admin-warehouse-edit', params: {id: data.item.name_lot}}"
                  target="_blank"
                >
                  <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-50 text-primary cursor-pointer"
                  />
                  <!-- @click="EditType(data.item)" -->
                </b-link>

                <feather-icon
                  v-if="data.item.amount_all"
                  v-b-tooltip.hover.top="`ตรวจสอบราคาแล้ว ${data.item.check_amount ? data.item.check_amount: 0} รายการ`"
                  icon="CheckIcon"
                  :class="`mr-50 text-${data.item.check_amount ? 'danger': 'primary'} cursor-pointer`"
                  @click="CheckAmount(data.item)"
                />
                <!-- check_amount -->
                <feather-icon
                  v-else
                  icon="Trash2Icon"
                  class="mr-50 text-danger cursor-pointer"
                  @click="DeleteData(data.item)"
                />
                <feather-icon
                  v-if="data.item.send_mail"
                  v-b-tooltip.hover.top="'ส่งเมล์'"
                  icon="MailIcon"
                  class="mr-50 text-danger cursor-pointer"
                  @click="EmailSend(data.item)"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.top="'ส่งเมล์'"
                  icon="MailIcon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="EmailSend(data.item)"
                />

                <!-- <feather-icon
                  icon="CheckIcon"
                  class="mr-50 text-danger cursor-pointer"
                  @click="Checking(data.item)"
                />

                <feather-icon
                  icon="MailIcon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="SendEmail(data.item)"
                /> -->

                <feather-icon
                  v-b-tooltip.hover.top="'ใบรับสินค้า'"
                  icon="FileTextIcon"
                  class="mr-50 text-primary cursor-pointer"
                  @click="Document(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-modal
        id="modal-add-new"
        :title="$t('addProductLot')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="submitAddNew()"
      >

        <label for="Type">{{ $t('transportType') }} <span class="text-danger">*</span></label>
        <div class="demo-inline-spacing mt-n1">
          <b-form-radio
            v-model="transportType"
            name="some-radios"
            value="1"
          >
            {{ $t('truck') }}
          </b-form-radio>
          <b-form-radio
            v-model="transportType"
            name="some-radios"
            value="2"
          >
            {{ $t('ship') }}
          </b-form-radio>
        </div>
      </b-modal>

      <b-modal
        ref="modal-print"
        :title="$t('PrintProductlot')"
        :ok-title="$t('print')"
        :cancel-title="$t('cancel')"
        size="lg"
        @ok="submitPrint()"
      >
        <div class="d-flex align-items-center">
          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('listlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchRight"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions"
                      :key="option.name_lot"
                      v-model="roleDetail"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? 'รถ':'เรือ' }}/{{ option.name_lot }}
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="w-10 p-50">
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100 mb-1"
                @click="moveToLeft"
              >
                <i class="fas fa-arrow-to-left" />
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-icon-only w-100"
                @click="moveToRight"
              >
                <i class="fas fa-arrow-from-left" />
              </button>
            </div>
          </div>

          <div class="w-45">
            <div class="card border">
              <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="checkAll2"
                    value="true"
                    class="custom-control-primary"
                    :indeterminate="isIndeterminate2"
                    @change="checkAllOptions"
                  />
                  <h6 class="mb-0">
                    <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} items
                  </h6>
                </div>

                <h6 class="mb-0">
                  {{ $t('selectlot') }}
                </h6>
              </div>

              <div class="p-1">
                <div class="income-order-search order-search">
                  <div class="anan-input search-input">
                    <div class="anan-input__inner anan-input__inner--normal">
                      <input
                        v-model="searchLeft"
                        type="text"
                        placeholder="Search here..."
                        class="anan-input__input"
                      >
                      <div class="anan-input__suffix">
                        <i class="anan-input__clear-btn anan-icon" />
                        <i class="anan-input__suffix-icon anan-icon">
                          <i class="fal fa-search" />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="role-items">
                  <b-form-group class="mt-1">
                    <b-form-checkbox
                      v-for="option in filteredOptions2"
                      :key="option.name_lot"
                      v-model="roleDetail2"
                      :value="option._id"
                      name="flavour-3a"
                    >
                      <div class="mb-1">
                        {{ option.transportType === 1 ? 'รถ':'เรือ' }}/{{ option.name_lot }}
                      </div>
                    </b-form-checkbox>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink,
  BFormSelect,
  BModal, BPagination, BFormRadio, BFormGroup, BFormCheckbox, BOverlay, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BPagination,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      role: '',
      searchLeft: '',
      searchRight: '',
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      checkAll: false,
      checkAll2: false,
      search: '',
      selected: null,
      transportType: null,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      number_lot: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        title: '',
        content: '',
      },
      selectedRadio: null,
      optionsRadio: [
        { text: this.$t('all'), value: null },
        { text: this.$t('truck'), value: 1 },
        { text: this.$t('ship'), value: 2 },
      ],
      itemss: [
        {
          id: 1,
          remark: 'สินค้าเข้าโกดัง',
          inWarehouse: '2020-10-10',
          exWarehouse: '2020-10-10',
          toThai: '2020-10-10',
          amount: 100,
          amountExported: 100,
          latestEditor: 'admin',
          attachment: '1',
          print: '1',
        },
      ],
      showOver: false,
      search_val: '',
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name_lot', label: this.$t('number'), thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'remark', label: this.$t('remarks'), sortable: false, thStyle: { width: '15%', fontSize: '1rem' }, thClass: 'text-center',
        },
        {
          key: 'come_chinesewarehouse', label: this.$t('enterWarehouse'), sortable: false, thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'out_chinesewarehouse', label: this.$t('exitWarehouse'), sortable: false, thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'come_thaiwarehouse', label: this.$t('arrivedThailand'), sortable: false, thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount_all', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount_out', label: this.$t('exportedAmount'), sortable: false, thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'last_edit', label: this.$t('lastEditedBy'), sortable: false, thStyle: { width: '5%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_file', label: this.$t('attachedFile'), sortable: false, thStyle: { width: '10%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'print', label: this.$t('print'), sortable: false, thStyle: { width: '5%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '110%', fontSize: '1rem' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    isIndeterminate() {
      return this.roleDetail.length > 0 && this.roleDetail.length < this.optionsLeft.length
    },
    filteredOptions() {
      if (this.searchLeft) {
        const searchTerm = this.searchLeft.toLowerCase()
        return this.optionsLeft.filter(option => option.name_lot.toLowerCase().includes(searchTerm))
      }
      return this.optionsLeft
    },
    isIndeterminate2() {
      return this.roleDetail2.length > 0 && this.roleDetail2.length < this.optionsRight.length
    },
    filteredOptions2() {
      if (this.searchRight) {
        const searchTerm2 = this.searchRight.toLowerCase()
        return this.optionsRight.filter(option => option.name_lot.toLowerCase().includes(searchTerm2))
      }
      return this.optionsRight
    },
  },
  async mounted() {
    this.getData()
    this.getLotOp()
    this.sharedMethod()
  },
  methods: {
    submitPrint() {
      this.$refs['modal-print'].hide()
      this.showOver = true
      // eslint-disable-next-line no-underscore-dangle
      const LotID = Object.values(this.optionsRight).map(item => item._id)
      this.$http.post('/print/lotall', LotID, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          window.open(pdfUrl, '_blank')
          this.showOver = false
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
          this.showOver = false
        })
    },
    printLot(item) {
      // console.log(item)
      try {
        this.showOver = true
        // eslint-disable-next-line no-underscore-dangle
        const obj = [item._id]
        this.$http.post('/print/lotall', obj, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(blob)
            window.open(pdfUrl, '_blank')
            this.showOver = false
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
            this.showOver = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    Document(item) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        window.open(`https://api.anan-cargo.com/api/print/pickup/${item._id}`)
        // window.open(`http://127.0.0.1:4444/api/print/pickup/${this.ID}`)
      } catch (e) {
        console.log(e)
      }
    },
    async submitAddNew() {
      const params = {
        number_lot: this.number_lot,
        transportType: this.transportType,
      }
      // add date to table
      // this.itemss.push(params)
      await this.$http.post('/Lotorder/store', params)
        .then(response => {
          // eslint-disable-next-line no-underscore-dangle
          this.$router.push({ name: 'admin-warehouse-edit', params: { id: response.data.data.name_lot } })
        })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    getType(type) {
      if (type === 1) {
        return 'รถ'
      } return 'เรือ'
    },
    CheckAmount(data) {
      this.$swal({
        title: '<h3 style="color: #141414">ต้องการตรวจสอบราคา</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: `ต้องการตรวจสอบราคา ล๊อต ${data.name_lot} ?`,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.showOver = true
          const obj = {
            ID: data._id,
          }
          this.$http.post('Lotorder/CheckAmount', obj)
            .then(response => {
              if (response.data.status) {
                this.getData()
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">สำเร็จ</h3>',
                  html: `<p style="color: #141414">อัพเดทราคาเรียบร้อย ${response.data.amount} รายการ</p>`,
                  showConfirmButton: false,
                  timer: 2000,
                })
                this.showOver = false
              }
            })
            .catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: '<h3 style="color: #141414">อัพเดทราคาไม่สำเร็จ</h3>',
                // html: `<p style="color: #141414">อัพเดทราคาเรียบร้อย ${response.data.amount} รายการ</p>`,
                showConfirmButton: false,
                timer: 2000,
              })
              this.showOver = false
            })
        }
      })
    },
    EmailSend(data) {
      this.$swal({
        title: '<h3 style="color: #141414">ต้องการส่งเมล์</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: `ส่งเมล์แจ้งเตือนล๊อต ${data.name_lot} ?`,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.showOver = true
          const params = {
            ID: data._id,
          }
          this.$http.post('Lotorder/SendMailLot', params)
            .then(response => {
              if (response.data.status) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">สำเร็จ</h3>',
                  html: `<p style="color: #141414">ส่งเมล์แจ้งเตือนล๊อต ${data.name_lot} สำเร็จ </p>`,
                  showConfirmButton: false,
                  timer: 2000,
                })
                this.getData()
                this.showOver = false
              }
            }).catch(err => {
              this.$swal({
                icon: 'error',
                title: '<h3 style="color: #141414">ไม่สำเร็จ</h3>',
                html: '<p style="color: #141414">`ส่งเมล์แจ้งเตือนล๊อต ${data.name_lot} ไม่สำเร็จ`</p>',
                showConfirmButton: false,
                timer: 1500,
              })
              this.showOver = false
              console.log(err)
            })
        }
      })
    },
    DeleteData(data) {
      this.$swal({
        title: '<h3 style="color: #141414">ต้องการลบ</h3>',
        iconHtml: '<img src="/delete.png" width="50px">',
        showCancelButton: true,
        text: `ล๊อต ${data.name_lot} ?`,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        iconColor: '#ff0000',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.showOver = true
          const obj = {
            ID: data._id,
          }
          this.$http.post('Lotorder/DeleteData', obj)
            .then(response => {
              if (response.data) {
                this.getData()
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  html: `<p style="color: #141414">ลบข้อมูลล๊อต ${data.name_lot} สำเร็จ</p>`,
                  showConfirmButton: false,
                  timer: 2000,
                })
                this.showOver = false
              }
            }).catch(err => {
              console.log(err)
              this.$swal({
                icon: 'error',
                title: '<h3 style="color: #141414">ลบข้อมูลไม่สำเร็จ</h3>',
                html: `<p style="color: #141414">ลบข้อมูลล๊อต ${data.name_lot} ไม่สำเร็จ</p>`,
                showConfirmButton: false,
                timer: 2000,
              })
              this.showOver = false
            })
        }
      })
    },
    moveToLeft() {
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.concat(this.optionsRight.filter(option => this.roleDetail2.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.filter(option => !this.roleDetail2.includes(option._id))
      this.roleDetail2 = []
      this.checkAll = false
      this.checkAll2 = false
    },
    moveToRight() {
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.concat(this.optionsLeft.filter(option => this.roleDetail.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.filter(option => !this.roleDetail.includes(option._id))
      this.roleDetail = []
      this.checkAll = false
      this.checkAll2 = false
    },
    checkAllOptions() {
      if (this.checkAll) {
        // eslint-disable-next-line no-underscore-dangle
        this.roleDetail = this.optionsLeft.map(option => option._id)
      } else {
        this.roleDetail = []
      }
      if (this.checkAll2) {
        // eslint-disable-next-line no-underscore-dangle
        this.roleDetail2 = this.optionsRight.map(option => option._id)
      } else {
        this.roleDetail2 = []
      }
    },
    async getLotOp() {
      try {
        const { data: res } = await this.$http.get('/Lotorder/getLotID')
        this.optionsLeft = res
      } catch (e) {
        console.log(e)
      }
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search_val: this.search_val,
          type: this.selectedRadio,
        }
        const { data: res } = await this.$http.get('/Lotorder/getLot', { params })
        this.itemss = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-warehouse-edit', params: { id: data.name_lot } })
    },
    checkedfile(filess) {
      const obj = {
        pathfile: filess,
      }
      this.$http.post('/Lotorder/view', obj, { responseType: 'blob' })
        .then(response => {
          if (obj.pathfile.endsWith('.xls')) {
            const text = obj.pathfile.split('/')
            const fileName = text[text.length - 1]
            const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${fileName}` // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click()
          }
          if (obj.pathfile.endsWith('.xlsx')) {
            const text = obj.pathfile.split('/')
            const fileName = text[text.length - 1]
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${fileName}` // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click()
          }
          if (obj.pathfile.endsWith('.pdf')) {
            const text = obj.pathfile.split('/')
            const fileName = text[text.length - 1]
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${fileName}` // ระบุชื่อไฟล์ที่คุณต้องการบันทึก
            link.click()
          }
        })
        .catch(error => {
          console.error('Error:', error)
        })
    },
    // DeleteData(data) {
    //   this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
    //     title: 'ยืนยันการลบข้อมูล',
    //     size: 'sm',
    //     buttonSize: 'sm',
    //     okVariant: 'danger',
    //     okTitle: 'YES',
    //     cancelTitle: 'NO',
    //     footerClass: 'p-2',
    //     hideHeaderClose: false,
    //     centered: true,
    //   })
    //     .then(value => {
    //       if (value) {
    //         const params = {
    //           id: data.id,
    //         }
    //         this.$http.post('groupbyuser/list/delete', params).then(() => {
    //           this.getData(this.isActive)
    //           this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
    //             title: 'สำเร็จ',
    //             variant: 'success',
    //             solid: true,
    //             autoHideDelay: 1500,
    //           })
    //         })
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
  },
}
</script>
<style scoped>

.swal2-icon.swal2-error {
  border-color: #ff0000 !important;
}

</style>
